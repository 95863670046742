/** Media Query **/
/**** Animation *****/
.animate-something {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.i-animat {
  -moz-transition-property: background-color;
  -webkit-transition-property: background-color;
  -o-transition-property: background-color;
  transition-property: background-color;
  -moz-transition-duration: 0.07s;
  -webkit-transition-duration: 0.07s;
  -o-transition-duration: 0.07s;
  transition-duration: 0.07s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.clear {
  display: table;
  clear: both;
  content: " ";
}
html,
body {
  position: relative;
  height: 100%;
  font-size: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  vertical-align: baseline;
  color: #2c2c2c;
}
a {
  text-decoration: none;
}
* input,
* textarea {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -khtml-user-select: initial;
  -ms-user-select: initial;
}
a {
  text-decoration: none !important;
  outline: none;
}
a:active {
  background-color: transparent;
}
a:hover {
  text-decoration: none;
}
strong {
  font-weight: 400;
}
ul,
li {
  padding: 0;
  margin: 0;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  /* border-color: rgba(126, 239, 104, 0); */
  box-shadow: none;
  outline: none;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}
header {
  border-bottom: 1px solid #f1f2f4;
}
nav {
  padding: 15px 30px;
}
nav img {
  height: 40px;
}

.email-input {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 339.89px;
  height: 60.71px;
}

.red-error {
  border: 1px solid #cc0022;
}

.error-message {
  width: 339.89px;
  height: auto;
  background: #feeaed;
  margin-top: -15px;
  margin-bottom: 53px;
  padding: 20px 20px;
  color: #cc0022;
  font-size: 12px;
}

.error-message .underline {
  text-decoration: underline;
  font-weight: 600;
}

.home-divide {
  height: 100vh;
  display: flex;
  /* grid-template-columns: minmax(150px, 45%) 1fr; */
}

@media only screen and (max-width: 529px) {
  .email-input {
    width: 100%;
  }

  .home-divide {
    display: block;
    width: 100vw;
    height: 100vh;
    background: pink;
  }

  .pagination div {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .pagination {
    display: flex;
    flex-direction: column-reverse;
  }

  .pagination .relative {
    position: relative;
  }

  .pagination .back-btn {
    position: absolute;
    left: 30px;
    right: 0;
    top: 20px;
  }

  .mobile-phase {
    display: block !important;
    padding: 15px;
    border-bottom: 2px solid #2d7ff9;
  }
  .mobile-phase span {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .mobile-phase p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .auth-mobile {
    margin-top: 276px !important;
    width: 100% !important;
  }
}
.home-divide .signup-field {
  background: #ffffff;
  padding: 100px;
  width: 50%;
}
@media only screen and (max-width: 529px) {
  .home-divide .signup-field {
    padding: 0 15px;
    width: 100vw;
    height: 100vh;
  }

  .home-divide .signup-field .header {
    margin-top: 0px !important;
  }
}

.home-divide .signup-field .header {
  margin-top: -40px;
  text-align: center;
}
.home-divide .signup-field .header img {
  height: 40px;
}

.home-divide .signup-field .form-wrap {
  margin-top: 59%;
}
.home-divide .signup-field .form-wrap label {
  margin-bottom: 10px;
}
.home-divide .signup-field .form-wrap button {
  background: #2d7ff9;
  border-radius: 0px 10px 10px 20px;
  color: #ffffff;
  padding: 10px 30px;
}
.home-divide .signup-image {
  background: url(/img/hands-bg.png),
    linear-gradient(180deg, #08a3ff 0%, #003777 100%);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 50%, auto;
  width: 50%;
}
@media only screen and (max-width: 529px) {
  .home-divide .signup-image {
    visibility: hidden;
  }

  .phases {
    display: none;
    margin-top: 50px;
    padding: 20px;
  }

  .phases li {
    display: inline-block;
  }

  .pagination .link,
  .pagination button {
    width: 100%;
    margin-bottom: 12px;
  }

  .hide-on-mobile {
    display: none;
    margin: 0;
    padding: 0;
  }
}
.home-divide .signup-image .signup-text-bottom {
  padding: 60px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
}
.home-divide .signup-image .signup-text-bottom h2 {
  font-size: 35px;
  margin-bottom: 10px;
  font-weight: bold;
}
.home-divide .signup-image .signup-text-bottom p {
  font-size: 18px;
}
.wrap {
  border: 1px solid #e1e1e1;
  padding: 20px 20px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.wrap .qstn {
  font-family: Graphik;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: #5a6163;
}
.wrap input {
  margin-right: 3px;
}
.wrap label {
  margin-right: 15px;
}
.mt20 {
  margin-top: 20px;
}
.title-header {
  margin: 30px 0 40px;
}
.title-header h1 {
  font-size: 20px;
  color: #2c2c2c;
  padding-bottom: 10px;
}

.title-header h1.hr {
  border-bottom: 1.5px solid #dfdfdf;
  margin-bottom: 10px;
}
label {
  margin-bottom: 0;
}
.checkbox-long {
  margin-bottom: 20px;
}
.checkbox-long label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}
.checkbox-long input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -6px;
  overflow: hidden;
}
.sub-title {
  margin-bottom: 30px;
  font-size: 14px;
}
.italize {
  margin-top: 30px;
  font-style: italic;
  font-size: 14px;
}
input {
  margin-bottom: 15px;
}
.basic-info {
  margin-top: 40px;
  padding: 20px;
}
.phases {
  margin-top: 80px;
  padding: 0 0 0 50px;
  border-right: 1px solid #f1f2f4;
  height: 100%;
}
.phases li {
  list-style: none;
  font-size: 12px;
  text-align: right;
  padding: 15px;
  border-top: 0.5px solid #f1f2f4;
}
.phases li img {
  margin-left: 10px;
  height: 12px;
}
.phases .active-tab {
  font-weight: bold;
}
.pagination {
  margin: 80px 0 50px;
  padding: 20px;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.pagination a,
.pagination .link,
.pagination button {
  border: 1px solid #2d7ff9;
  background: #2d7ff9;
  border-radius: 0px 10px 10px 20px;
  color: #ffffff;
  padding: 15px 30px;
  cursor: pointer;
}

.pagination .relative {
  position: relative;
}

.pagination .back-btn {
  position: absolute;
  left: 9px;
  right: 0;
  top: 20px;
}

.payment-form label {
  font-size: 14px;
}
.payment-form button {
  background: #2d7ff9;
}
.loader-container {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
}
.cursor-blocked {
  cursor: not-allowed !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.has-error {
  border: 1px solid salmon !important;
}

input[type="checkbox"] {
  margin-right: 10px;
}

ul {
  list-style-type: none;
}

.btn {
  outline: none;
  border: none;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-evenly {
  justify-content: space-evenly;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.justify-between {
  justify-content: space-around;
}

.badges {
  padding: 1.15em 0.7em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 5px;
}

.badges.correct {
  border: 1px solid #3bb273;
  color: #3bb273;
}

.badges.wrong {
  color: #ef4813;
  border: 1px solid #ef4813;
}

.badges.full {
  display: block;
  white-space: normal;
  margin-bottom: 15px;
  line-height: 1.4em;
  width: 100%;
}

.disclaimer {
  border-radius: 4px;
  padding: 10px 20px;
  text-align: justify;
  display: flex;
  align-items: center;
}

.disclaimer svg {
  margin-right: 8px;
}

.disclaimer.wrong-answer {
  background: rgba(239, 72, 19, 0.2);
  color: #ef4813;
  font-size: 13px;
  font-weight: bold;
  width: auto;
}

.disclaimer.correct-answer {
  background: rgba(3, 183, 129, 0.2);
  color: #03b781;
  font-size: 13px;
  font-weight: bold;
  width: auto;
}

.syndicate-control {
  height: 55px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
}

.syndicate-control.readOnly {
  background: #e9e9e9;
}

.wrapper label,
.payment-form label {
  margin-bottom: 7px;
}

.p-sub {
  margin-top: -20px !important;
  font-size: 10px;
}

.link {
  border: 1px solid #2d7ff9;
  background: #2d7ff9;
  border-radius: 0px 10px 10px 20px;
  color: #ffffff;
  padding: 15px 30px;
  cursor: pointer;
}

.back-link {
  background: #ffffff;
  border: 1px solid #383838;
  border-radius: 0px 10px 10px 20px;
  color: #383838;
  padding: 15px 30px;
  cursor: pointer;
}

.success-center {
  margin: 0 auto;
  text-align: center;
}

.mobile-phase {
  display: none;
}

.w-100 {
  width: 100% !important;
}

.w-inherit {
  width: inherit !important;
}

.form-protected {
  position: relative;
}
.form-protected input {
  padding: 0 10px;
}

.form-protected svg {
  position: absolute;
  left: 95%;
  top: 20px;
}

.error-color {
  color: #cc0022 !important;
  text-decoration: underline;
}
