.modal__load__container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: hsla(0, 0%, 0%, 0.3);
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  overflow: auto;
  z-index: 2000;
}

.spinner {
  margin: 100px auto;
  width: 100px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner > div {
  background-color: #5a5a5a;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-left: 5px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
